import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map } from 'rxjs';

export interface CreateGreenBoxRequest {
  name: string;
  address: string;
  email: string;
  phone: string;
}

@Injectable()
export class GreenBoxService {
  
  constructor(
      protected http: HttpClient,
	  @Inject('API_URL') private apiContext: string) {
  }
  
  public async createGreenBoxRequest(request: CreateGreenBoxRequest): Promise<void> {
    
    await firstValueFrom(this.http.post<void>(`${this.apiContext}/greenbox-requests`, request));
  }
}

	